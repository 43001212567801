@import "variables";

.login_wrapper {
    min-height: 100vh;
    display: flex;
    align-items: center;
}

.login_inner {
    width: 100%;
    max-width: 360px;
    margin: 0 auto;
    padding: 15px;
}

.login_block {
    padding: 30px;
    background-color: #fff;
    box-shadow: @box-shadow-base;
}
