@primary-color: #1890ff;
@link-color: #1890ff;
@success-color: #52c41a;
@warning-color: #faad14;
@error-color: #f5222d;
@font-size-base: 14px;
@heading-color: rgb(0, 0, 0);
@text-color: rgb(0, 0, 0);
@text-color-secondary: rgba(0, 0, 0, 0.45);
@disabled-color: rgba(0, 0, 0, 0.25);
@border-radius-base: 2px;
@border-color-base: #d9d9d9;
@box-shadow-base: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
@border-color-base-50: rgba(217, 217, 217, .5);

@default-margin: 24px;
@default-margin-75: 16px;
@default-margin-50: 12px;
@default-margin-25: 6px;
@default-padding: 24px;
@default-padding-75: 16px;
@default-padding-50: 12px;
@default-padding-25: 6px;
@default-border-color: #eee;

@table-header-bg: transparent;
@table-header-color: #878787;
@table-font-size: @font-size-base;
@table-font-size-md: @table-font-size;
@table-font-size-sm: 13px;

@menu-item-padding: 0 16px;

.color-primary {
    color: @link-color;
}

.color-heading {
    color: @heading-color;
}

.color-secondary {
    color: @text-color-secondary;
}

.color-success {
    color: @success-color
}

.color-warning {
    color: @warning-color;
}

.color-danger {
    color: @error-color;
}

.color-border-title {
    color: #c0c0c0;
}

.color-black {
    color: #000000;
}

.color-black-75 {
    color: rgba(0, 0, 0, .75);
}

.color-black-50 {
    color: rgba(0, 0, 0, .5);
}

.color-black-25 {
    color: rgba(0, 0, 0, .25);
}

.color-grey-base {
    color: @border-color-base;
}
