.color-primary {
  color: #1890ff;
}
.color-heading {
  color: #000000;
}
.color-secondary {
  color: rgba(0, 0, 0, 0.45);
}
.color-success {
  color: #52c41a;
}
.color-warning {
  color: #faad14;
}
.color-danger {
  color: #f5222d;
}
.color-border-title {
  color: #c0c0c0;
}
.color-black {
  color: #000000;
}
.color-black-75 {
  color: rgba(0, 0, 0, 0.75);
}
.color-black-50 {
  color: rgba(0, 0, 0, 0.5);
}
.color-black-25 {
  color: rgba(0, 0, 0, 0.25);
}
.color-grey-base {
  color: #d9d9d9;
}
.form_field_wrapper {
  margin-bottom: 16px;
}
.form_field_title {
  margin-bottom: 5px;
  color: #000000;
}
.form_field_error {
  margin-top: 5px;
  font-size: 13px;
  color: #f5222d;
}
