.color-primary {
  color: #1890ff;
}
.color-heading {
  color: #000000;
}
.color-secondary {
  color: rgba(0, 0, 0, 0.45);
}
.color-success {
  color: #52c41a;
}
.color-warning {
  color: #faad14;
}
.color-danger {
  color: #f5222d;
}
.color-border-title {
  color: #c0c0c0;
}
.color-black {
  color: #000000;
}
.color-black-75 {
  color: rgba(0, 0, 0, 0.75);
}
.color-black-50 {
  color: rgba(0, 0, 0, 0.5);
}
.color-black-25 {
  color: rgba(0, 0, 0, 0.25);
}
.color-grey-base {
  color: #d9d9d9;
}
.content_header {
  display: flex;
  justify-content: space-between;
  padding: 24px;
  border-bottom: 1px solid #eee;
  background-color: #fff;
}
.content_header_title {
  display: flex;
  align-items: center;
  font-size: 22px;
  color: #000000;
}
.content_header_description {
  font-size: 14px;
}
.content_subtitle_wrapper {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}
.content_subtitle {
  font-size: 15px;
  color: #434343;
}
.content_box {
  margin-bottom: 24px;
}
.content_block {
  padding: 24px;
  border-bottom: 1px solid #eee;
  background-color: #fff;
}
.content_box_white {
  background-color: #ffffff;
}
.content_filter {
  padding: 24px;
  border-bottom: 1px solid #eee;
  background-color: #ffffff;
}
.content_filter_button {
  margin-bottom: 24px;
}
.content_tabs {
  background-color: #ffffff;
}
.content_tabs .ant-tabs-nav {
  margin-bottom: 0!important;
  padding: 0 24px !important;
}
.suggestion-item {
  padding: 8px;
  text-align: left;
  background-color: #fff;
  cursor: pointer;
}
.suggestion-item--active {
  padding: 8px;
  text-align: left;
  cursor: pointer;
  background-color: #fafafa;
}
.content-bottom-border {
  border-bottom: 1px solid #eee;
}
