.fs-11 {
    font-size: 11px;
}

.fs-12 {
    font-size: 12px;
}

.fs-13 {
    font-size: 13px;
}

.fs-14 {
    font-size: 14px;
}

.fs-15 {
    font-size: 15px;
}

.fs-16 {
    font-size: 16px;
}

.fs-17 {
    font-size: 17px;
}

.fs-18 {
    font-size: 18px;
}

.fs-20 {
    font-size: 20px;
}

.fs-24 {
    font-size: 24px;
}

.fs-28 {
    font-size: 28px;
}

.fw_100 {
    font-weight: 100;
}

.fw_200 {
    font-weight: 200;
}

.fw_300 {
    font-weight: 300;
}

.fw_400 {
    font-weight: 400;
}

.fw_500 {
    font-weight: 500;
}

.fw_600 {
    font-weight: 600;
}

.fw_700 {
    font-weight: 700;
}

.fw_800 {
    font-weight: 800;
}
