@import "variables";

.ant-layout-header.header {
    position: fixed;
    right: 0;
    left: 280px;
    padding: 0 24px;
    box-shadow: @box-shadow-base;
    background: #ffffff;
    z-index: 999;
}
