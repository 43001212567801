@import "../../../style/variables";

.avatar_picker {
    display: flex;
    align-items: center;
    margin-right: 24px;
    line-height: 1.2;
}

.avatar_picker_icon {
    margin-right: 10px;
}

.avatar_picker_name {
    font-size: 14px;
    color: @heading-color;
}

.avatar_picker_email {
    font-size: 13px;
    color: @text-color-secondary;
}
