@import "../../style/variables";

.content_loader {
    padding: 30px 0;
    text-align: center;
    animation: showLoader 0s 0.5s forwards;
    visibility: hidden;
}

.content_loader_icon {
    margin-bottom: 15px;
    font-size: 42px;
    color: @primary-color;
}

.content_loader_title {
    font-size: 16px;
    color: @text-color;
}

.content_loader_description {
    font-size: 13px;
    color: @text-color-secondary;
}

@keyframes showLoader {
    to {
        visibility: visible;
    }
}
