@import "variables";

.profile_image {
    position: relative;
}

.profile_image_edit {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: none;
    background: rgba(255, 255, 255, 0.5);
    border-radius: 50%;
    text-align: center;
    line-height: 128px;
    font-size: 20px;
    cursor: pointer;
}

.profile_image:hover .profile_image_edit {
    display: block;
}
