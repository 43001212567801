.color-primary {
  color: #1890ff;
}
.color-heading {
  color: #000000;
}
.color-secondary {
  color: rgba(0, 0, 0, 0.45);
}
.color-success {
  color: #52c41a;
}
.color-warning {
  color: #faad14;
}
.color-danger {
  color: #f5222d;
}
.color-border-title {
  color: #c0c0c0;
}
.color-black {
  color: #000000;
}
.color-black-75 {
  color: rgba(0, 0, 0, 0.75);
}
.color-black-50 {
  color: rgba(0, 0, 0, 0.5);
}
.color-black-25 {
  color: rgba(0, 0, 0, 0.25);
}
.color-grey-base {
  color: #d9d9d9;
}
.login_wrapper {
  min-height: 100vh;
  display: flex;
  align-items: center;
}
.login_inner {
  width: 100%;
  max-width: 360px;
  margin: 0 auto;
  padding: 15px;
}
.login_block {
  padding: 30px;
  background-color: #fff;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
}
