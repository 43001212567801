@import "variables";

.form_field_wrapper {
    margin-bottom: 16px;
}

.form_field_title {
    margin-bottom: 5px;
    color: @text-color;
}

.form_field_error {
    margin-top: 5px;
    font-size: 13px;
    color: @error-color;
}
