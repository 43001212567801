.color-primary {
  color: #1890ff;
}
.color-heading {
  color: #000000;
}
.color-secondary {
  color: rgba(0, 0, 0, 0.45);
}
.color-success {
  color: #52c41a;
}
.color-warning {
  color: #faad14;
}
.color-danger {
  color: #f5222d;
}
.color-border-title {
  color: #c0c0c0;
}
.color-black {
  color: #000000;
}
.color-black-75 {
  color: rgba(0, 0, 0, 0.75);
}
.color-black-50 {
  color: rgba(0, 0, 0, 0.5);
}
.color-black-25 {
  color: rgba(0, 0, 0, 0.25);
}
.color-grey-base {
  color: #d9d9d9;
}
.list_info {
  margin: 0!important;
}
.list_info_wrapper .list_info:nth-child(even) {
  background-color: #f5f5f5;
}
.list_info_label {
  padding: 16px;
  color: rgba(0, 0, 0, 0.45);
}
.list_info_value {
  padding: 16px;
  color: #000000;
}
.list_info_actions {
  padding: 16px;
}
.list_info_content {
  padding: 16px;
}
.list_item_title {
  font-weight: 600;
}
