@import "./libs/bootstrap.less";

/* tables */

.ant-table-thead > tr > th {
    font-weight: 400;
}

/* tabs */

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    font-weight: 400;
}

/* select */
.border-title > .ant-select-selector {
    .bg-white;
    .ant-select-selection-item {
        .text-secondary;
    }
}

.border-title.ant-input-highlighted > .ant-select-selector {
    .ant-input-highlighted;
}

.border-title.ant-input-dimmed > .ant-select-selector {
    .ant-select-selection-item {
        .ant-input-dimmed;
    }
}

/* input */
.input-with-suffix-text-secondary > .ant-input {
    .text-secondary;
}

.input-with-suffix-text-secondary.ant-input-dimmed > .ant-input {
    .ant-input-dimmed;
}

.ant-input-highlighted {
    border-color: #57a8e9;
}

.ant-input-dimmed {
    color: #cccccc !important;
    border-color: #ebebeb !important;
}

/* datePicker */
.border-title > .ant-picker-input > input {
    .text-secondary;
}

.ant-input-dimmed > .ant-picker-input > input[disabled] {
    .ant-input-dimmed;
}
