@import "variables";

.list_info {
    margin: 0!important;
}

.list_info_wrapper .list_info:nth-child(even) {
    background-color: #f5f5f5;
}

.list_info_label {
    padding: 16px;
    color: @text-color-secondary;
}

.list_info_value {
    padding: 16px;
    color: @heading-color;
}

.list_info_actions {
    padding: 16px;
}

.list_info_content {
    padding: 16px;
}

.list_item_title {
    font-weight: 600;
}
