button.job-edit-button {
  background-color: #FFE5A8;
  color: #CE7C29;
}
button.job-edit-button:focus {
  background-color: #FFE5A8;
  color: #CE7C29;
}
button.job-cancel-button {
  background-color: #ECECEC;
  color: #7F7F7F;
}
button.job-cancel-button:focus {
  background-color: #ECECEC;
  color: #7F7F7F;
}
button.job-confirm-button {
  background-color: #1890FF;
  color: #FFFFFF;
}
button.job-confirm-button:focus {
  background-color: #1890FF;
  color: #FFFFFF;
}
button.job-accept-button {
  background-color: #52c41a;
  color: #FFFFFF;
}
button.job-accept-button:focus {
  background-color: #52c41a;
  color: #FFFFFF;
}
button.job-set-called-button {
  background-color: #A0E4AA;
  color: #358750;
}
button.job-set-called-button:focus {
  background-color: #A0E4AA;
  color: #358750;
}
button.job-remove-button {
  background-color: #FF4D4F;
  color: #FFFFFF;
}
button.job-remove-button:focus {
  background-color: #FF4D4F;
  color: #FFFFFF;
}
