@import "~antd/dist/antd";
@import "variables";

body {
    font-family: 'DM Sans', sans-serif;
}

body, html {
    width: 100%;
    height: 100%;
}

html body {
    position: relative;
    margin: 0;
    background: #f3f5f7;
    color: #000000;
    word-wrap: break-word;
    overflow-x: hidden;
}

.wrapper {
    height: 100vh;
    min-height: 100vh;
}

.inner {
    width: 100%;
    max-width: 1400px;
    margin: 0 auto;
    padding: 0 15px;
}

.content {
    width: 100%;
    max-width: 1600px;
}

.grecaptcha-badge {
    display: none!important;
}

.cursor_pointer {
    cursor: pointer;
}

.search_input_vertical_container {
    border-radius: @border-radius-base;
    border: 1px solid @border-color-base;
    height: 100%;
}

.location-suggestion-item {
    background-color: #ffffff;
    cursor: pointer;
    padding: 3px 0 3px 5px;
}

.location-suggestion-item:hover {
    background-color: #fafafa;
    cursor: pointer;
    padding: 3px 0 3px 5px;
}

.centered-table {
    max-width: 600px;
}

.public_inner_sm {
    max-width: 576px;
    margin: 0 auto;
    padding: 0 24px;
}

.public_content {
    padding: 24px 0;
}

.public_wrapper {
    min-height: 100vh;
}

.public_title_wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
}

.public_title_wrapper > div {
    margin-bottom: 24px;
}

.public_title {
    font-size: 30px;
    font-weight: 700;
    color: @heading-color;
}

.content_data_item {
    padding: 10px 15px;
    border-bottom: 1px solid @border-color-base-50;
}