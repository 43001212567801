@import "variables";

.content_header {
    display: flex;
    justify-content: space-between;
    padding: @default-padding;
    border-bottom: 1px solid @default-border-color;
    background-color: #fff;
}

.content_header_title {
    display: flex;
    align-items: center;
    font-size: 22px;
    color: #000000;
}

.content_header_description {
    font-size: 14px;
}

.content_subtitle_wrapper {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
}

.content_subtitle {
    font-size: 15px;
    color: #434343;
}

.content_box {
    margin-bottom: @default-margin;
}

.content_block {
    padding: @default-padding;
    border-bottom: 1px solid @default-border-color;
    background-color: #fff;
}

.content_box_white {
    background-color: #ffffff;
}

.content_filter {
    padding: @default-padding;
    border-bottom: 1px solid @default-border-color;
    background-color: #ffffff;
}

.content_filter_button {
    margin-bottom: @default-margin;
}

.content_tabs {
    background-color: #ffffff;
}

.content_tabs .ant-tabs-nav {
    margin-bottom: 0!important;
    padding: 0 @default-padding!important;
}

.suggestion-item {
    padding: 8px;
    text-align: left;
    background-color: #fff;
    cursor: pointer;
}
  
.suggestion-item--active {
    padding: 8px;
    text-align: left;
    cursor: pointer;
    background-color: #fafafa;
}

.content-bottom-border {
    border-bottom: 1px solid @default-border-color;
}