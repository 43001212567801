@job-edit-button-bg: #FFE5A8;
@job-edit-button-color: #CE7C29;
@job-cancel-button-bg: #ECECEC;
@job-cancel-button-color: #7F7F7F;
@job-confirm-button-bg: #1890FF;
@job-confirm-button-color: #FFFFFF;
@job-accept-button-bg: #52c41a;
@job-accept-button-color: #FFFFFF;
@job-set-called-button-bg: #A0E4AA;
@job-set-called-button-color: #358750;
@job-remove-button-bg: #FF4D4F;
@job-remove-button-color: #FFFFFF;

button.job-edit-button {
    background-color: @job-edit-button-bg;
    color: @job-edit-button-color;
}
button.job-edit-button:focus {
    background-color: @job-edit-button-bg;
    color: @job-edit-button-color;
}

button.job-cancel-button {
    background-color: @job-cancel-button-bg;
    color: @job-cancel-button-color;
}

button.job-cancel-button:focus {
    background-color: @job-cancel-button-bg;
    color: @job-cancel-button-color;
}

button.job-confirm-button {
    background-color: @job-confirm-button-bg;
    color: @job-confirm-button-color;
}

button.job-confirm-button:focus {
    background-color: @job-confirm-button-bg;
    color: @job-confirm-button-color;
}
button.job-accept-button {
    background-color: @job-accept-button-bg;
    color: @job-accept-button-color;
}

button.job-accept-button:focus {
    background-color: @job-accept-button-bg;
    color: @job-accept-button-color;
}

button.job-set-called-button {
    background-color: @job-set-called-button-bg;
    color: @job-set-called-button-color;
}

button.job-set-called-button:focus {
    background-color: @job-set-called-button-bg;
    color: @job-set-called-button-color;
}

button.job-remove-button {
    background-color: @job-remove-button-bg;
    color: @job-remove-button-color;
}

button.job-remove-button:focus {
    background-color: @job-remove-button-bg;
    color: @job-remove-button-color;
}