.color-primary {
  color: #1890ff;
}
.color-heading {
  color: #000000;
}
.color-secondary {
  color: rgba(0, 0, 0, 0.45);
}
.color-success {
  color: #52c41a;
}
.color-warning {
  color: #faad14;
}
.color-danger {
  color: #f5222d;
}
.color-border-title {
  color: #c0c0c0;
}
.color-black {
  color: #000000;
}
.color-black-75 {
  color: rgba(0, 0, 0, 0.75);
}
.color-black-50 {
  color: rgba(0, 0, 0, 0.5);
}
.color-black-25 {
  color: rgba(0, 0, 0, 0.25);
}
.color-grey-base {
  color: #d9d9d9;
}
.ant-layout-header.header {
  position: fixed;
  right: 0;
  left: 280px;
  padding: 0 24px;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  background: #ffffff;
  z-index: 999;
}
